@font-face {
  font-family: "Perroquet";
  src: url(./fonts/Regular/Perroquet-Regular.otf);
}

@font-face {
  font-family: "Perroquet-Bold";
  src: url(./fonts/Bold/Perroquet-Bold.otf);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(37, 0, 55);
  width: 100vw;
  height: auto;
  font-family: Perroquet, sans-serif;
}

/* Logo Perroquet version Desktop */
.logodes {
  display: block !important;
  padding: 0.7rem 0.3rem;
  background-color: rgb(118, 0, 255);
}

/* Animations des flocons en background  */
@keyframes falling {
  from { transform: translate(0, -100%) rotate(0deg) scale(0.8); }
  to { transform: translate(0, 120vh) rotate(360deg) scale(1.2); }
}

@keyframes opacity_flakes {
  0% { opacity: 1; }
  100% { opacity: 1; }
}